import React from "react";
import icon_close from "../assets/icon_close.svg";

function RechnerModal({ setModalVersicherungOpen }) {
	return (
		<div className="Rechner-Modal">
			<div
				className="Rechner-Modal-Closer"
				onClick={() => setModalVersicherungOpen(false)}
				onKeyDown={(e) => e.key === "escape" && setModalVersicherungOpen(false)}
			/>
			<div className="Rechner-Modal-Content">
				<button
					className="Rechner-Modal-Close"
					onClick={() => setModalVersicherungOpen(false)}
					onKeyDown={(e) =>
						e.key === "escape" && setModalVersicherungOpen(false)
					}
					type="button"
				>
					<img
						src={icon_close}
						width={24}
						height={24}
						alt="Fenster Schliessen"
					/>
				</button>
				<div className="flex flex-col">
					<h2>LEISTUNGEN IM ÜBERBLICK</h2>
					<h3>(§§2,3 AVB) FIRMENRADL VERSICHERUNG Stand 09/2024</h3>
				</div>
				<div className="row">
					{/* FIRMENRADL */}
					<ContentFirmenradl />

					{/* FIRMENRADL INSPEKTION */}
					<ContentFirmenradlInspektion />
				</div>
				<div className="row">
					{/* FIRMENRADL AUSFALLSCHUTZ */}
					<ContentAusfallschutz />
				</div>
			</div>
		</div>
	);
}

export default RechnerModal;

function ContentFirmenradl() {
	return (
		<div className="col">
			<h3>FIRMENRADL</h3>
			<h4>REPARATURKOSTEN</h4>
			<ul>
				<li>Fall-/Sturzschäden</li>
				<li>Unfallschäden</li>
				<li>Unsachgemäße Handhabung</li>
				<li>Vandalismus</li>
				<li>Feuchtigkeit</li>
				<li>Elektronikschäden</li>
				<li>
					<em>Verschleiß</em>
				</li>
			</ul>
			<p>inkl. Arbeitslohn und Ersatzteile</p>
			<h4>TOTALSCHADEN</h4>
			<p>
				Vereinbarte Ablösesumme aus dem zugrundeliegenden Leasingvertrag,
				maximal jedoch die Versicherungssumme.
			</p>
			<h4>DIEBSTAHL</h4>
			<p>
				Vereinbarte Ablösesumme aus dem zugrundeliegenden Leasingvertrag,
				maximal jedoch die Versicherungssumme.
			</p>
			<h4>INSPEKTION</h4>
			<p style={{ height: "20px" }} />
			<h4>PICK UP SERVICE</h4>
			<p>
				Kostenübernahme bei Ausfall des FIRMENRADL während einer Ausfahrt durch:
			</p>
			<ul>
				<li>Beschädigung oder Diebstahl des FIRMENRADL</li>
				<li>
					Ausfall des Motors/der Motorunterstützung auf Grund eines Defekts
				</li>
				<li>Mechanischer Mangel durch Ketten oder Rahmenbruch</li>
				<li>Reifenpanne</li>
				<li>Unfall/Sturz</li>
			</ul>
			<h4>AKKUSCHUTZ</h4>
			<ul>
				<li>Verschleiß, Abnutzung, Alterung</li>
				<li>Unsachgemäße Handhabung</li>
				<li>Feuchtigkeit</li>
				<li>Elektronikschaden</li>
			</ul>
		</div>
	);
}

function ContentFirmenradlInspektion() {
	return (
		<div className="col">
			<h3>FIRMENRADL INSPEKTION</h3>
			<h4>REPARATURKOSTEN</h4>
			<ul>
				<li>Fall-/Sturzschäden</li>
				<li>Unfallschäden</li>
				<li>Unsachgemäße Handhabung</li>
				<li>Vandalismus</li>
				<li>Feuchtigkeit</li>
				<li>Elektronikschäden</li>
				<li>
					<em>Verschleiß</em>
				</li>
			</ul>
			<p>inkl. Arbeitslohn und Ersatzteile</p>
			<h4>TOTALSCHADEN</h4>
			<p>
				Vereinbarte Ablösesumme aus dem zugrundeliegenden Leasingvertrag,
				maximal jedoch die Versicherungssumme.
			</p>
			<h4>DIEBSTAHL</h4>
			<p>
				Vereinbarte Ablösesumme aus dem zugrundeliegenden Leasingvertrag,
				maximal jedoch die Versicherungssumme.
			</p>
			<h4>INSPEKTION</h4>
			<p>Eine jährliche Inspektion (bis maximal € 100,-)</p>
			<h4>PICK UP SERVICE</h4>
			<p>
				Kostenübernahme bei Ausfall des FIRMENRADL während einer Ausfahrt durch:
			</p>
			<ul>
				<li>Beschädigung oder Diebstahl des FIRMENRADL</li>
				<li>
					Ausfall des Motors/der Motorunterstützung auf Grund eines Defekts
				</li>
				<li>Mechanischer Mangel durch Ketten oder Rahmenbruch</li>
				<li>Reifenpanne</li>
				<li>Unfall/Sturz</li>
			</ul>
			<h4>AKKUSCHUTZ</h4>
			<ul>
				<li>Verschleiß, Abnutzung, Alterung</li>
				<li>Unsachgemäße Handhabung</li>
				<li>Feuchtigkeit</li>
				<li>Elektronikschaden</li>
			</ul>
		</div>
	);
}

function ContentAusfallschutz() {
	return (
		<div style={{ marginTop: "1.5rem" }} className="">
			<h3>AUSFALLSCHUTZ FÜR ARBEITGEBER</h3>
			<h4>Austritt aus dem Unternehmen</h4>
			<p>
				Variante 1: Mitarbeiter kauft das FIRMENRADL aus dem Vertrag <br />
				Variante 2: Neuer Arbeitgeber übernimmt den Vertrag (inklusive
				Bearbeitungsgebühr) <br /> Variante 3: Anderer Mitarbeiter übernimmt den
				Vertrag <br /> Variante 4: Mitarbeiter gibt das FIRMENRADL inklusive
				Zubehör bei einem Fachhändler retour <br /> Variante 5: Arbeitgeber
				übernimmt das FIRMENRADL
			</p>

			{/* --- */}
			<h4>Ableben</h4>
			<p>
				Variante 1: anderer Mitarbeiter übernimmt das FIRMENRADL <br />
				Variante 2: FIRMENRADL wird an einen Fachhändler retour gegeben
			</p>

			{/* --- */}
			<h4>Erwerbsunfähigkeit</h4>
			<p>Gesetzliche Entgeltfortzahlung ist abzuwarten</p>

			{/* --- */}
			<h4>Krankenstand</h4>
			<p>Raten werden übernommen sobald die volle Lohnfortzahlung entfällt</p>

			{/* --- */}
			<h4>Mutterschutz / Elternkarenz / Papamonat</h4>
			<p>Raten werden übernommen bis der Mitarbeiter wieder zurück ist</p>

			{/* --- */}
			<h4>Bildungskarenz</h4>
			<p>maximal 4 Monate</p>

			{/* --- */}
			<h4>Saisonarbeitskräfte</h4>
			<p>maximal 4 Monate</p>

			{/* --- */}
			<h4>Kurzarbeit</h4>
			<p>maximal 4 Monate</p>

			{/* --- */}
			<h4>Wiedereinberufung / Truppenübung</h4>
			<p>volle Ratenübernahme</p>

			{/* --- */}
			<h4>Wiedereingliederungsteilzeit</h4>
			<p>Zur Erleichterung der Rückkehr zur Arbeit nach langer Krankheit</p>

			{/* --- */}
			<h4>Ausschlüsse</h4>
			<ul>
				<li>Unterschreitung von kollektivlichem Mindestlohn</li>
				<li>Sabbatical</li>
				<li>unbezahlter Urlaub / Freistellung</li>
				<li>Pfändung</li>
				<li>Krankenstand bei voller Entgeltfortzahlung</li>
				<li>Präsenzdienst /Zivildienst</li>
			</ul>
		</div>
	);
}
